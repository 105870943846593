.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;
  background-color: #ef3124;
  width: 100%;

  &-direction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 6px 0 20px;
  }

  &-title {
    font-size: 17px;
    line-height: 1;
    color: #fff;
    font-weight: normal;
    letter-spacing: 0.17px;

    @media only screen and (max-width: '768px') {
      font-size: 14px;
    }
  }

  &-count {
    font-size: 17px;
    line-height: 1;
    color: #fff;
    font-weight: normal;
    letter-spacing: 0.17px;

    @media only screen and (max-width: '768px') {
      font-size: 14px;
    }
  }

  &__progress-wrapper {
    width: 100%;
    height: 8px;
    background-color: #fff;
  }

  &__progress {
    height: 8px;
    background-color: #ef3124;
    opacity: 0.5;
    transition: all linear 0.3s;
  }
}


.test {
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 14px 20px;
    background-color: #ef3124;

    a {
      color: white;
      text-decoration: none;

      &.active {
        border-bottom: 1px solid white;
      }
    }
  }

  &__header-direction {
    position: relative;
    top: -5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 !important;
    padding: 14px 20px;
    background-color: #ef3124;
    box-sizing: border-box;

    .test__header-title {
      font-size: 17px;
      line-height: 1;
      color: #000;
      font-weight: normal;
      letter-spacing: 0.17px;

      @media only screen and (max-width: '768px') {
        font-size: 14px;
      }
    }

    .test__header-count {
      font-size: 17px;
      line-height: 1;
      color: #000;
      font-weight: normal;
      letter-spacing: 0.17px;

      @media only screen and (max-width: '768px') {
        font-size: 14px;
      }
    }
  }

  &__header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 7px;
    font-size: 32px;
    line-height: 1;
    color: #fff;

    &-start {
      width: 26px;
      height: 32px;
      background-image: url('../../img/a@3x.png');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }

    &-finish {
      width: 26px;
      height: 32px;
      background-image: url('../../img/b@3x.png');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      animation: hidden linear 0.5s infinite;
    }
  }

  &__progress-wrapper {
    width: 100%;
    height: 8px;
    background-color: #fff;

    .test__progress {
      height: 8px;
      background-color: #ef3124;
      opacity: 0.5;
      transition: all linear 0.3s;
    }
  }
}