.test-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.test__question-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  overflow-x: auto;
  width: 100%;
  margin-bottom: 50px;
}

button {
  width: 300px;
  margin: 0 auto 25px;
  padding: 7px 50px;
  background-color: #ef3124;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border: 0;
  cursor: pointer;

  &:hover {
    background-color: #ce2a1e;
  }
}

table {
  max-width: 1000px;
  min-width: 800px;
  padding: 0 8px;
  margin: 0 auto;
}

thead {
  td {
    border-bottom: 2px solid #ce2a1e;
    color: #ce2a1e;

    &:first-child {
      padding-left: 0;
    }
  }
}

* {
  box-sizing: border-box;
}

td {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 10px;
  border-bottom: 1px solid #bebebe;

  &:first-child {
    padding-left: 10px;
  }
}

tbody {
  tr:first-child {
    td:first-child {
      position: relative;
  
      &:first-child:after {
        content: '1';
        position: absolute;
        top: 15px;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        background-color: #fbcb53;
      }
    }
  }

  tr:nth-child(2) {
    td:first-child {
      position: relative;
  
      &:first-child:after {
        content: '2';
        position: absolute;
        top: 15px;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        background-color: #c4c4c4;
      }
    }
  }

  tr:nth-child(3) {
    td:first-child {
      position: relative;
  
      &:first-child:after {
        content: '3';
        position: absolute;
        top: 15px;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        background-color: #df835c;
      }
    }
  }
}

.test {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 14px 20px;
    background-color: #ef3124;
  }

  &__header-direction {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 6px 0 20px;

    .test__header-title {
      font-size: 17px;
      line-height: 1;
      color: #fff;
      font-weight: normal;
      letter-spacing: 0.17px;

      @media only screen and (max-width: '768px') {
        font-size: 14px;
      }
    }
  }

  &__header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 32px;
    line-height: 1;
    color: #fff;
  }

  &__progress-wrapper {
    width: 100%;
    height: 8px;
    background-color: #fff;

    .test__progress {
      height: 8px;
      background-color: #ef3124;
      opacity: 0.5;
      transition: all linear 0.3s;
    }
  }

  .test__question {
    width: 100%;
    max-width: 850px;
    padding: 20px 20px 65px;
    box-sizing: border-box;

    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
    }
  }
}


@keyframes hidden {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}